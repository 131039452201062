<template>
  <div
    id="global-header"
    v-if="!itemAsIFrame"
    class="global-header"
    :style="{ '--scroll-percentage': scrollPercentage + '%' }"
  >
    <div class="global-header__top">
      <div class="container">
        <div class="wrapper">
          <NuxtLink class="navbar-logo" to="/" @click.prevent="subNavigationEnabled = false">
            <NuxtImg :src="logoUrl" width="139" height="66" />
          </NuxtLink>
          <div
            id="header-hamburger-wrapper"
            class="hamburger-wrapper"
            @click="() => (isOpen ? closeMenu() : openMenu())"
          >
            <button class="hamburger">
              <IconHamburger v-if="!isOpen" />
              <IconX v-else />
            </button>
          </div>
          <div id="header-search-btn-wrapper" class="search-btn-wrapper" @click="toggleSearchModal">
            <button class="search-btn" type="button">
              <IconX v-if="showSearchModal" />
              <IconMagnifyingGlass v-else />
            </button>
          </div>
          <div ref="searchModalRef" class="search-modal-container">
            <div v-if="showSearchModal" class="search-modal">
              <div>
                <input
                  id="searchModalBox"
                  v-model="searchQuery"
                  type="text"
                  placeholder="Search"
                  @keydown.enter="submitSearch"
                />
                <div
                  v-if="peopleResponse.length > 0 && !breakpoint && searchQuery.length > 0"
                  class="perspectiveSearchHolder"
                >
                  <div class="predictiveSearchCategory nonSelectable">People</div>
                  <div
                    v-for="people in peopleResponse"
                    :key="people.id"
                    class="people-title-link-container"
                  >
                    <a class="people-title-link" @click="navigateToUrl(people.id)">{{
                      people.displayName
                    }}</a>
                  </div>
                  <div v-if="totalResults > 4" class="people-title-link-container">
                    <NuxtLink
                      class="people-title-link people-title-link-view-all"
                      :to="peopleUrlList"
                      >View All
                    </NuxtLink>
                  </div>
                </div>
              </div>
              <button @click="submitSearch">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-navigation" :style="`display: ${subNavigationEnabled ? 'flex' : 'none'}`">
      <Transition name="global-header__fade">
        <div v-if="showSubNavigation" class="global-header__sub-navigation">
          <div class="container">
            <h1 class="global-header__sub-navigation__title">
              {{ pageStore.pageTitle }}
            </h1>
          </div>
        </div>
      </Transition>
      <Transition name="global-header__fade">
        <div v-if="showSubNavigation" ref="scrollRef" class="container global-header__slider">
          <div
            v-for="item in tableOfContents"
            :key="item.system.id"
            :class="{
              'global-header__slider__item': true,
              active: tableOfContentsActiveId == item.system.id
            }"
            @click="scrollToContent(item.system.id)"
          >
            {{ item.elements.tableOfContentLinksMenuTitle.value }}
          </div>
        </div>
      </Transition>
      <Transition name="global-header__fade">
        <div v-if="showSubNavigation" class="global-header__slider-controls">
          <div
            class="global-header__slider-controls__overlay-left"
            :style="{ display: showLeftArrow ? 'inherit' : 'none' }"
          />
          <div
            class="global-header__slider-controls__overlay-right"
            :style="{ display: showRightArrow ? 'inherit' : 'none' }"
          />
          <div
            class="global-header__slider-controls__arrow-left"
            :style="{ display: showLeftArrow ? 'inherit' : 'none' }"
            @click="onArrowLeft"
          />
          <div
            class="global-header__slider-controls__arrow-right"
            :style="{ display: showRightArrow ? 'inherit' : 'none' }"
            @click="onArrowRight"
          />
        </div>
      </Transition>
    </div>
    <LazyFullWidthMenu v-if="breakpoint" />
    <LazyFlyoutMenu v-else />
    <span
      :class="{
        'global-header__search-overlay': true,
        'is-open': showSearchModal
      }"
    ></span>
  </div>
</template>
<script lang="ts" setup>
import { useCssVar, useEventListener, useMediaQuery, useWindowScroll } from '@vueuse/core'
import { usePageStore } from '@/stores/page.ts'
import type { TableOfContentLinks } from '~/models'
import findLast from 'lodash/findLast'
import delay from 'lodash/delay'
import debounce from 'lodash/debounce'
import type { SearchResultPeopleItem } from '~/types/SearchResultItem'

const itemAsIFrame = useState<boolean>('itemAsIframe')

const pageStore = usePageStore()
const siteStore = useSiteStore()

const favIconUrl = computed(() => siteStore.siteConfig.elements?.favicon?.value[0]?.url || '')

useHead(() => ({
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: favIconUrl.value 
    }
  ]
}))

const windowScroll = useWindowScroll()
const { scrollRef, scrollLeft, scrollRight } = useScroll()
const { showLeftArrow, showRightArrow, checkVisibility } = useSliderVisibility(scrollRef)
const updateArrows = () => delay(checkVisibility, 300)
const showSearchModal = ref(false)
const searchQuery = ref('')
const totalResults = ref(0)
const peopleResponse = ref([] as SearchResultPeopleItem[])
const scrollPercentage = ref(0)
const logoUrl = ref('')
if (siteStore.siteConfig.elements.websiteLogo.value.length > 0)
  logoUrl.value = siteStore.siteConfig.elements.websiteLogo.value[0].url
const searchModalRef = ref<HTMLElement | null>(null)
const { isOpen, openMenu, closeMenu } = useMenuState()
const ignoreClick = ref(false)

const headerHeight = parseFloat(useCssVar('--height-header').value)
const subNavigationHeight = parseFloat(useCssVar('--height-subnavigation').value)
const subNavigationEnabled = ref(false)
const showSubNavigation = ref(false)

const updateScrollPercentage = () => {
  const el = document.documentElement
  const documentHeight = el.scrollHeight - el.clientHeight
  scrollPercentage.value = (windowScroll.y.value / documentHeight) * 100
}

const navigateToUrl = async (id: string) => {
  const url = await pageStore.getUrlByItemId(id)
  if (url) {
    await navigateTo(url)
  }
}

const handleClickOutside = (event: MouseEvent) => {
  const modal = searchModalRef.value
  if (modal && !modal.contains(event.target as Node) && !ignoreClick.value) {
    showSearchModal.value = false
  }
}
const toggleSearchModal = () => {
  showSearchModal.value = !showSearchModal.value
  if (showSearchModal.value) {
    closeMenu()
  }
  ignoreClick.value = true
  setTimeout(() => {
    ignoreClick.value = false
    if (!!showSearchModal.value) {
      document.getElementById('searchModalBox')?.focus()
    }
  }, 10)
}

const submitSearch = async () => {
  if (searchQuery.value) {
    await navigateTo({
      path: '/search',
      query: {
        q: searchQuery.value || ''
      }
    })
  }
  showSearchModal.value = false
}

const updateSubNavigationVisibility = () => {
  const capabilitiesPage = document.getElementsByClassName('capabilities-page')
  const pageContentWrapper = document.getElementsByClassName('page-content').item(0) as HTMLElement
  if (capabilitiesPage.length == 0 || !pageContentWrapper) {
    subNavigationEnabled.value = false
    return
  }
  subNavigationEnabled.value = true
  const hero = document.getElementsByClassName('interior-hero').item(0) as HTMLElement
  const top = pageContentWrapper.getBoundingClientRect().top
  const pageNonHeroOffset = hero ? 0 : 300
  showSubNavigation.value = top + pageNonHeroOffset - headerHeight - subNavigationHeight <= 1
}

const breakpointMd = useCssVar('--media-query-md')
const breakpoint = computed(() =>
  breakpointMd.value ? useMediaQuery(`(max-width: ${breakpointMd.value})`).value : false
)
const { scrollToElementById } = useSmoothScroll()

const tableOfContents = ref<TableOfContentLinks[]>(pageStore.getTableOfContents())
const tableOfContentsActiveId = ref<string>('')

const filterTableOfContent = () => {
  const page = document.getElementById(pageStore.pageId)
  tableOfContentsActiveId.value = page?.id ?? ''
  const tableOfContentsElements = tableOfContents.value
    .filter((item) => item.elements.tableOfContentLinksMenuTitle.value.length > 0)
    .map((item) => document.getElementById(item.system.id))
    .filter((item) => item != null)
  tableOfContents.value = tableOfContents.value.filter((item) =>
    tableOfContentsElements.map((i) => i.id).includes(item.system.id)
  )
}

const reorderTableOfContents = () => {
  const tableOfContentsElements = tableOfContents.value
    .map((item) => document.getElementById(item.system.id))
    .filter((item) => item != null)
  const itemsWithPositions = tableOfContentsElements
    .map((element) => {
      if (element) {
        return {
          item: tableOfContents.value.find((item) => item.system.id === element.id),
          position: element.getBoundingClientRect().top
        }
      }
      return null
    })
    .filter((item) => item !== null) as { item: any; position: number }[]
  itemsWithPositions.sort((a, b) => a.position - b.position)
  tableOfContents.value = itemsWithPositions.map((item) => item.item)
  const lastElementTopOutOfViewport = findLast(tableOfContents.value, (e) => {
    const content = document
      .getElementById(e.system.id)
      ?.querySelector('.container, .container-fluid')
    if (!content) return false
    const computedStyle = getComputedStyle(content)
    const paddingTop = computedStyle.getPropertyValue('padding-top')
    const offset = parseFloat(paddingTop) * 2
    return content.getBoundingClientRect().top - (offset + 220) < 0
  })
  tableOfContentsActiveId.value = lastElementTopOutOfViewport
    ? lastElementTopOutOfViewport.system.id
    : pageStore.pageId
}

const scrollToContent = (id: string) => {
  const content = document.getElementById(id)?.querySelector('.container, .container-fluid')
  if (!content) return
  const computedStyle = getComputedStyle(content)
  const paddingTop = computedStyle.getPropertyValue('padding-top')
  const offset = parseFloat(paddingTop)
  scrollToElementById(id, offset + 200)
}

const onArrowLeft = () => {
  scrollLeft(300)
  updateArrows()
}

const onArrowRight = () => {
  scrollRight(300)
  updateArrows()
}

const updateHeader = () => {
  updateArrows()
  updateScrollPercentage()
  debounce(() => {
    updateSubNavigationVisibility()
    filterTableOfContent()
    reorderTableOfContents()
  }, 200)()
}

useRouter().beforeEach(() => {
  subNavigationEnabled.value = false
})

onMounted(() => {
  updateHeader()
  useEventListener(window, 'scroll', updateHeader)
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
const performSearch = async (query: string) => {
  peopleResponse.value = []
  totalResults.value = 0
  if (query) {
    const peopleSearch = await fetchPeopleSearch({
      capabilities: [],
      capability: '',
      location: '',
      position: '',
      searchValue: query,
      page: 1,
      size: 4,
      sort: ''
    })
    peopleResponse.value = (peopleSearch.data?.items as SearchResultPeopleItem[]) || []
    totalResults.value = peopleSearch.data?.totalCount || 0
  }
}

const debouncedPerformSearch = debounce(performSearch, 500)

watch(searchQuery, (newQuery: string) => {
  debouncedPerformSearch(newQuery)
})
</script>
