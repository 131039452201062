//for hamburger menu
import { ref } from 'vue'

const isOpen = ref(false)

export function useMenuState() {
  const openMenu = () => {
    isOpen.value = true
    document.body.classList.add('modal-open')
  }

  const closeMenu = () => {
    isOpen.value = false
    document.body.classList.remove('modal-open')
  }

  return {
    isOpen,
    openMenu,
    closeMenu
  }
}
